import React from 'react';
import styled from 'styled-components/macro';
import Link from 'gatsby-link';

import { IconButton } from '@material-ui/core';
import { ArrowBackIosSharp } from '@material-ui/icons';

import WhiteLogo from '../assets/img/GoGetLogoWhite.svg';
import BlackLogo from '../assets/img/black_logo.png';
import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const Container = styled.div`
  display: flex;
  min-width: 320px;
`;
const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const LogoWrapper = styled.div`
  padding-top: 8px;
  width: 104px;
  margin: auto;
`;
const LeftSideBar = styled.div`
  background: ${colors.primary};
  width: 185px;
  min-height: 100vh;
  position: fixed;
  display: none;

  @media (min-width: 980px) {
    display: block;
  }
`;
const Body = styled.div`
  width: calc(100% - 185px);
  margin-left: 185px;
  @media (max-width: 980px) {
    width: 100%;
    margin-left: unset;
  }
`;
const AppBarHeader = styled.div`
  background-color: ${colors.white};
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #e5e5e5;
  height: 63px;
`;
const Text = styled.p`
  font-size: ${fontSize.xLarge};
  color: ${colors.black};
`;
const StyledArrowBackIosSharp = styled(ArrowBackIosSharp)`
  && {
    color: ${colors.black};
  }
`;
const BlackLogoWrapper = styled.div`
  display: none;
  @media (max-width: 980px) {
    width: 59px;
    margin-top: 4px;
    display: block;
    margin-right: 1rem;
  }
`;
const BlackLogoImage = styled.img`
  height: 100%;
  width: 100%;
`;
const HeaderImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const AppGoGetLayout = ({
  children,
  title,
  onBackUrl,
  logoUrl,
  disableAppBar
}) => {
  return (
    <Container>
      <LeftSideBar>
        <LogoWrapper>
          <Link to={logoUrl ? logoUrl : null}>
            <Logo src={WhiteLogo} alt="goget-logo" />
          </Link>
        </LogoWrapper>
      </LeftSideBar>
      <Body>
        {!disableAppBar && (
          <AppBarHeader>
            <HeaderImageWrapper>
              <IconButton component={Link} to={onBackUrl}>
                <StyledArrowBackIosSharp />
              </IconButton>
              <Text>{title}</Text>
            </HeaderImageWrapper>
            <BlackLogoWrapper>
              <BlackLogoImage src={BlackLogo} alt="goget-logo" />
            </BlackLogoWrapper>
          </AppBarHeader>
        )}
        {children}
      </Body>
    </Container>
  );
};

export default AppGoGetLayout;
