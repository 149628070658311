import React from 'react';
import styled from 'styled-components/macro';
import colors from '../assets/colors';

import Paper from '@material-ui/core/Paper';

import AppGoGetLayout from './AppGoGetLayout';

const MainBodyWrapper = styled.div`
  max-width: 613px;
  margin: 5rem auto 2rem auto;
  @media (max-width: 980px) {
    margin: 24px auto;
  }
  @media (max-width: 650px) {
    margin: 24px 14px;
  }
`;
const StyledPaper = styled(Paper)`
  && {
    background: ${colors.white};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 27px 22px 40px 22px;
    width: 613px;
    @media (max-width: 980px) {
      width: unset;
    }
  }
`;

const CareersLayout = props => {
  return (
    <AppGoGetLayout title="Careers" onBackUrl="/careers#exploreJobs">
      <MainBodyWrapper>
        <StyledPaper>{props.children}</StyledPaper>
      </MainBodyWrapper>
    </AppGoGetLayout>
  );
};

export default CareersLayout;
