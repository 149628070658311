import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';
import Button from '../../components/Button';

const StyledButton = styled(Button)`
  max-width: 320px !important;
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
const Text = styled.div`
  color: ${colors.black};
  font-size: ${fontSize.large};
  font-weight: 400;
`;
const Anchor = styled(Link)`
  color: ${colors.primary};
`;
const Email = styled.a`
  text-decoration: none;
  color: ${colors.white};
`;
const Wrapper = styled.div`
  padding: 0 27px 38px 27px;
`;
const StyledClose = styled(Close)`
  && {
    color: ${colors.black};
  }
  cursor: pointer;
`;
const IconWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex: 1;
  margin: 14px;
`;

const DialogApplyJob = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconWrapper>
        <StyledClose onClick={onClose} />
      </IconWrapper>
      <Wrapper>
        <Text>
          GoGet.My abides by Personal Data Protection Act 2010 (“Act”) to
          maintain the privacy and confidentiality of Personal Data shared with
          us when you apply. Due to the regulations under the Act, any Personal
          Data collected, stored, processed and used for job application
          purposes shall comply with the rules and regulations listed.
          Information shared will only be used within GoGet.My.
        </Text>
        <br />
        <Text>
          Please refer to the link <Anchor to="/privacy_policy">here</Anchor> to
          read on GoGet.My Privacy Policy
        </Text>
        <br />
        <Text>
          I agree with the GoGet.My Privacy Policy by clicking on “Email us at
          hiring@goget.my”.
        </Text>
        <br />
        <br />
        <StyledButtonWrapper>
          <StyledButton
            noShadow
            primary
            background="primary"
            className="primary"
          >
            <Email href="mailto: hiring@goget.my">
              EMAIL US AT HIRING@GOGET.MY
            </Email>
          </StyledButton>
        </StyledButtonWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default DialogApplyJob;
