import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components/macro';

import MetaImg from '../assets/img/meta/index.png';
import HeaderSeo from '../components/HeaderSeo';
import ApplyJobDialog from '../components/ApplyJobDialog';
import CareersLayout from '../components/CareersLayout';
import { StructuredText } from 'react-datocms';

import {
  StyledBodyText,
  Divider,
  MarginBottom
} from '../views/CareersPage/jobDetailsCareers.style';

const BodyWrapper = styled.div`
  p {
    line-height: 21.79px;
    font-weight: 400;
    font-size: 0.875rem;
    margin: 0px 4px 0px 0px;
  }
`;

const CareerPage = props => {
  const { data } = props;
  const { datoCmsCareer } = data;

  return (
    <>
      <HeaderSeo
        title={datoCmsCareer?.jobTitle}
        description={datoCmsCareer?.seoDescription}
        image={MetaImg}
        keywords={datoCmsCareer?.seoKeywords}
        pathname={datoCmsCareer?.slug}
      />
      <CareersLayout>
        <MarginBottom>
          <b>{datoCmsCareer?.jobTitle},</b> {datoCmsCareer?.department}
        </MarginBottom>
        <StyledBodyText>{datoCmsCareer?.location}</StyledBodyText>
        <Divider />
        <BodyWrapper>
          <StructuredText data={datoCmsCareer?.jobDescription} />
        </BodyWrapper>
        <ApplyJobDialog />
      </CareersLayout>
    </>
  );
};

export const query = graphql`
  query CareerPageQuery($pageIdJim: String) {
    datoCmsCareer(originalId: { eq: $pageIdJim }) {
      originalId
      department
      jobTitle
      slug
      seoKeywords
      jobDescription {
        value
      }
      location
      seoDescription
    }
  }
`;

export default CareerPage;
