import React from 'react';
import styled from 'styled-components/macro';

import { Button } from '@material-ui/core';

import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const StyledButton = styled(Button)`
  font-family: 'Open Sans', sans-serif !important;
  &.primary {
    box-shadow: ${props =>
      props.noshadow === 'true' ? 'unset' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};
    border-radius: 8px;
    max-width: 230px;
    width: 100%;
    height: 60px;
    color: ${props =>
      props.background === 'primary' ? colors.white : colors.primary};
    background: ${props =>
      props.background === 'primary' ? colors.primary : colors.white};
    &:hover {
      color: ${props =>
        props.background === 'primary' ? colors.white : colors.primary};
      background: ${props =>
        props.background === 'primary' ? colors.primary : colors.white};
    }
    font-style: normal;
    font-weight: bold;
    font-size: ${fontSize.xLarge};
    line-height: 25px;
  }
`;

const Banner = ({
  variant,
  primary,
  background,
  children,
  className,
  onClick,
  noshadow,
  props
}) => {
  return (
    <StyledButton
      {...props}
      noshadow={noshadow}
      className={(primary ? 'primary ' : 'secondary ') + className}
      background={background}
      variant={variant}
      color="primary"
      onClick={() => onClick && onClick()}
    >
      {children}
    </StyledButton>
  );
};

export default Banner;
