// this file is used in pages/careers/...js because it cant be used inside the pages folder.
import styled from 'styled-components/macro';
import fontSize from '../../assets/fontSize';

export const StyledTitle = styled.p`
  font-weight: 700;
  font-size: ${fontSize.large};
  text-decoration: underline;
  line-height: 19.07px;
  margin-top: 21px;
`;
export const StyledSubtitle = styled.p`
  font-weight: 600;
  font-size: ${fontSize.large};
  line-height: 19.07px;
  margin-top: 12px;
`;
export const StyledBodyText = styled.p`
  line-height: 21.79px;
  font-weight: 400;
  font-size: ${fontSize.large};
  margin: 0 4px 0 0;
`;
export const Divider = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const Dot = styled.div`
  display: list-item;
  margin-left: 20px;
  margin-bottom: 4px;
`;
export const MarginBottom = styled.div`
  margin-bottom: 8px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2rem;
`;
export const SubTitle = styled.div`
  font-size: ${fontSize.large};
  font-weight: 600;
`;

export const SubPoint = styled.li`
  list-style-type: lower-alpha;
  margin-bottom: 4px;

  ::marker {
    font-size: ${fontSize.large};
  }
`;

export const StyledOrderedList = styled.ol`
  margin: 4px 0;
`;

export const StyledUnorderedList = styled.ul`
  margin: 4px 0;
`;

export const AddPoint = styled.li`
  list-style-type: lower-roman;
  margin-bottom: 4px;

  ::marker {
    font-size: ${fontSize.large};
  }
`;

export const NumPoint = styled.li`
  ::marker {
    font-size: ${fontSize.large};
  }
`;
