import React, { useState } from 'react';

import Button from '../components/Button';

import DialogApplyJob from '../views/CareersPage/DialogApplyJob';

import { ButtonWrapper } from '../views/CareersPage/jobDetailsCareers.style';

const ApplyJobDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonWrapper onClick={() => setIsOpen(true)}>
        <Button noShadow primary background="primary" className="primary">
          APPLY FOR THIS JOB
        </Button>
      </ButtonWrapper>
      <DialogApplyJob isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default ApplyJobDialog;
